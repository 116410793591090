import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class ScrollAnime {
  constructor() {
    let scrollPos;
    let cache = {};
    let animeArray = [];
    let animeTxtScroll = [];
    let initFunc = () => {
      initCache();
      registerEvents();
      loader();
    }

    let initCache = () => {
      if(document.querySelector('.anime-txt')) {
        cache.animeTxt = document.querySelectorAll('.anime-txt');
      }
      if (document.querySelector('.anime-txt_scroll')) {
        cache.animeTxtScroll = document.querySelectorAll('.anime-txt_scroll');
        for (let i = 0; i < cache.animeTxtScroll.length; i++) {
          animeTxtScroll.push(anime({
            targets: cache.animeTxtScroll[i],
            translateY: ['350%', '0%'],
            opacity: [0, 1],
            duration: 1200,
            easing: 'easeOutCirc',
            autoplay: false
          }));
        }
      }

      if (document.querySelector('.anime-txt_scroll')) {
        for (let i = 0; i < cache.animeTxtScroll.length; i++) {
          if (((scrollPos + (window.innerHeight)) > utils.getOffsetTop(cache.animeTxtScroll[i]))) {
            let percent = (scrollPos + (window.innerHeight) - (utils.getOffsetTop(cache.animeTxtScroll[i]))) / (window.innerHeight);
            animeTxtScroll[i].seek(percent * 1.4 * animeTxtScroll[i].duration);
          }
        }
      }

      if(document.querySelector('.anime-img')) {
        cache.animeImg = document.querySelectorAll('.anime-img');
      }
      if(document.querySelector('.anime-titre')) {
        cache.animeTitre = document.querySelectorAll('.anime-titre');
      }

      if(document.querySelector('.module-03-intro_texte')) {
        cache.intros = document.querySelectorAll('.module-03-intro_texte');
        cache.introsAnime = document.querySelectorAll('.module-03-intro_texte .txt p');
        for (let i = 0; i < cache.introsAnime.length; i++) {
          const parent = cache.introsAnime[i];
          let top = 0;
          let tmp = '';

          // Replace text with individual spans
          parent.innerHTML = parent.innerHTML.replace(/(\S+)/g, (match) => {
            return match.startsWith('<strong>') && match.endsWith('</strong>') ?
            `<span><strong>${match.substring(8, match.length - 9)}</strong></span>` :
            `<span>${match}</span>`;
          });

          let div = parent.children;
          for (let i = 0; i < div.length; i++) {
            let rect = div[i].getBoundingClientRect().top;
            if (top < rect) tmp += '</div>' + '<div class="line">';
            top = rect;
            tmp += div[i].outerHTML + ' ';
          }
          parent.innerHTML = tmp += '</div>';

          if(i == cache.introsAnime.length-1) {
            const lineArray = document.querySelectorAll('.module-03-intro_texte .line');
            for (let ii = 0; ii < lineArray.length; ii++) {
              animeArray.push(anime({
                targets: lineArray[ii],
                backgroundPositionX: ['-167%', '-267%'],
                paddingBottom: [15, 0],
                opacity: [0, 1],
                scale:[0.96, 1],
                delay: (ii * 300),
                duration: 1000,
                easing: 'linear',
                autoplay: false
              }));
            }
          }
        }
      };

      if(document.querySelector('.anime-key')) {
        cache.animeKey = document.querySelectorAll('.anime-key');
      }

    };

    let registerEvents = () => {
      window.addEventListener('scroll', scrollDetect);
    };


    let loader = () => {
      // if(document.querySelector('.loader')) {
      if (sessionStorage.getItem("loaderState") !== "seen") {
        sessionStorage.setItem('loaderState', 'seen');
        anime({
          targets: globalVar.scrollElement,
          top: 0,
          duration: 0,
        });
        anime({
          targets:  document.querySelector('body'),
          opacity: 1,
          duration: 400,
        });
        anime({
          targets: document.querySelector('.loader'),
          opacity: [1,0],
          scale: [1, 1.8],
          delay: 600,
          duration: 1000,
          easing: 'easeOutQuad',
          complete: function() {
            scrollDetect();
            document.querySelector('.loader').remove();
          }
        });
      } else {
        document.querySelector('.loader').remove();
        anime({
          targets:  document.querySelector('body'),
          opacity: 1,
          duration: 400,
        });
        scrollDetect();
      }
    };

    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;


      // TEXTE
      if(document.querySelector('.anime-txt')) {
        for (let i = 0; i < cache.animeTxt.length; i++) {
          if(((scrollPos+(window.innerHeight/1.2)) > utils.getOffsetTop(cache.animeTxt[i])) && (!cache.animeTxt[i].classList.contains('animated'))){
            cache.animeTxt[i].classList.add('animated');
            anime({
              targets: cache.animeTxt[i],
              translateY: [50, 0],
              skewX: [5,0],
              skewY: [5,0],
              opacity: [0,1],
              delay: 600 + (i * 100),
              duration: 800,
              easing: 'easeOutCirc',
            });
          }
        }
      }


      if(document.querySelector('.anime-txt_scroll')) {
        for (let i = 0; i < cache.animeTxtScroll.length; i++) {
          if(((scrollPos+(window.innerHeight)) > utils.getOffsetTop(cache.animeTxtScroll[i]))){
            let percent = (scrollPos + (window.innerHeight)-(utils.getOffsetTop(cache.animeTxtScroll[i])))/(window.innerHeight);;
            animeTxtScroll[i].seek(percent * 1.4 * animeTxtScroll[i].duration);
          }
        }
      }


      // IMAGE
      if(document.querySelector('.anime-img')) {
        for (let i = 0; i < cache.animeImg.length; i++) {
          if(((scrollPos+(window.innerHeight/1.2)) > utils.getOffsetTop(cache.animeImg[i])) && (!cache.animeImg[i].classList.contains('animated'))){
            cache.animeImg[i].classList.add('animated');
            anime({
              targets: cache.animeImg[i],
              translateY: [50, 0],
              opacity: [0,1],
              scale: [0.95, 1],
              delay: (i * 50),
              duration: 800,
              easing: 'easeOutCirc',
            });
          }
        }
      }


      // TITRE
      if(document.querySelector('.anime-titre')) {
        for (let i = 0; i < cache.animeTitre.length; i++) {
          if(((scrollPos+(window.innerHeight/1.2)) > utils.getOffsetTop(cache.animeTitre[i])) && (!cache.animeTitre[i].classList.contains('animated'))){
            cache.animeTitre[i].classList.add('animated');
            anime({
              targets: cache.animeTitre[i].querySelector('.inner'),
              translateY: ['350%', '0%'],
              rotate: [5, 0],
              skewX: [5,0],
              skewY: [5,0],
              duration: 800,
              easing: 'easeOutCirc',
            });
          }
        }
      }

      if(document.querySelector('.module-03-intro_texte')) {

        for (let i = 0; i < cache.intros.length; i++) {
          const lines = cache.intros[i].querySelectorAll('.line');
          for (let ii = 0; ii < lines.length; ii++) {
            if(((scrollPos+(window.innerHeight)) > utils.getOffsetTop(lines[ii]))){
              let percent = (scrollPos + (window.innerHeight)-(utils.getOffsetTop(lines[ii])))/(window.innerHeight);
              animeArray[ii].seek(percent * 2 * animeArray[ii].duration);
            }
          }

        }
      }

      // TEXTE
      if(document.querySelector('.anime-key')) {
        for (let i = 0; i < cache.animeKey.length; i++) {
          if(((scrollPos+(window.innerHeight/1.2)) > utils.getOffsetTop(cache.animeKey[i])) && (!cache.animeKey[i].classList.contains('animated'))){
            cache.animeKey[i].classList.add('animated');
            anime({
              targets: cache.animeKey[i],
              translateY: [50, 0],
              skewY: [2,0],
              skewX: [2,0],
              opacity: [0,1],
              delay: 600 + (i * 200),
              duration: 800,
              easing: 'easeOutCirc',
            });
            anime({
              targets: cache.animeKey[i].querySelector('.line'),
              backgroundPosition: ['100% 0%','0% 0%'],
              delay: 800 + (i * 200),
              duration: 600,
              easing: 'easeOutCirc',
            });
          }
        }
      }

    }

    initFunc();
  };
}
export { ScrollAnime }
