import globalVar from '../utils/globalVar'
import anime from '../libs/anime'

class Module_02_Logos {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
    }

    let initCache = () => {
      cache.bandeau = document.querySelectorAll('.bandeau');
      bandeau();
    }

    let bandeau = () => {
      for (let i = 0; i < cache.bandeau.length; i++) {
        let bandeau = cache.bandeau[i];
        let speed = 8000 * (cache.bandeau[i].offsetWidth / window.innerWidth);

        if(globalVar.tablet.matches) {
          speed = 3000 * (cache.bandeau[i].offsetWidth / window.innerWidth);
        }

        anime({
          targets: bandeau,
          translateX: ['0%', '-100%'],
          duration: speed,
          easing: 'linear',
          loop: true,
        });
      }

    }
    initFunc();
  }
}
export { Module_02_Logos }

